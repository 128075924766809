<template>
  <b-table
    :busy=isBusyProtocols
    :fields="fieldsProtocols"
    :id=table_id
    :items="selectedProtocolItems"
    hover
    primary-key="protocol_id"
  >
    <template v-slot:table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong> Обновляем информацию по протоколу...</strong>
      </div>
    </template>
    <template v-slot:cell(protocol_id)="data">
      <File :protocol_id="data.value" prefix="protocol"></File>
    </template>

  </b-table>

</template>

<script>
    import {mapGetters} from "vuex";
    import File from '@/components/File.vue'

    export default {
        name: "Protocol",
        components: {
            File,
        },
        props: {
            "protocol_id": {
                type: Number,
                required: true,
                default: 0
            },
        },
        data() {
            return {
                isBusyProtocols: false,
                selectedProtocolItems: [],
                fieldsProtocols: [
                    {key: 'protocol_date', label: 'Дата протокола', class: 'text-center' },
                    {key: 'protocol_name', label: 'Название протокола', class: 'text-left' },
                    {key: 'statcard_id', label: 'Номер приёма', class: 'text-center' },
                    {key: 'spec_name', label: 'Специальность', class: 'text-left' },
                    {key: 'user_name', label: 'ФИО врача', class: 'text-left' },
                    {key: 'spec_name', label: 'Специальность', class: 'text-left' },
                    {key: 'protocol_id', label: 'Протокол', class: 'text-center' },
                ]
            }
        },

        computed: {
            ...mapGetters(
                ['isLogged', 'PATIENT', 'SELECTEDBYEXPERT']),
            table_id() {
                return 'selectedProtocol-'
            },
        },
        mounted() {
            this.getFilterItems()
        },
        methods: {
            getFilterItems() {
                const id = this.protocol_id;
                if (id > 0) {
                    for (let i = 0; i < this.SELECTEDBYEXPERT.length; i++) {
                        if (this.SELECTEDBYEXPERT[i].id == id) {
                            if (this.SELECTEDBYEXPERT[i].type == 'protocol') {
                                this.selectedProtocolItems = this.SELECTEDBYEXPERT[i].list
                            }
                        }
                    }
                }
                return []
            },
        }
    }
</script>

<style scoped>

</style>
