<template>
  <div class="info">
    <TopMenu msg="Информация по пациенту: " />
    <PatientCard :mppId=mppId />
  </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import TopMenu from '@/components/TopMenu.vue'
    import PatientCard from '@/components/PatientCard.vue'
    export default {
        name: 'Info',
        components: {
            TopMenu,
            PatientCard
        },
        data () {
            return {
            }
        },
        computed: {
          ...mapGetters(
                  [
                    'mppId'
                  ])
        },
        methods: {
        }
    }
</script>

  <style scoped>

  </style>
