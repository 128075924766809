<template>
  <div>

    <b-card :sub-title=subTitle :title=title v-if="PATIENT">
      <b-card-text></b-card-text>
      <b-tabs card v-model="tabIndex">
        <b-tab active title="Услуги">
          <b-card-text>
            <b-table
                :busy=isBusyServices
                :current-page="currentPageServices"
                :fields="fieldsServices"
                :items="itemsProviderServices"
                :per-page="perPage"
                @row-clicked="(item) => servicesRowClicked(item)"
                hover
                id="servicesTable"
                primary-key="service_id"
                ref="servicesTable"
                show-empty
            >
              <template v-slot:head()="data">
                  <span class="text-nowrap">
                  {{ data.label }}
                    <b-icon-caret-up-fill
                        @click="changeDirection(data.field, '', 'servicesTable')"
                        v-if="data.field.direction == 'asc'"
                    />
                    <b-icon-caret-up
                        @click="changeDirection(data.field, 'asc', 'servicesTable')"
                        v-if="(data.field.direction == '' || data.field.direction == 'desc')"
                    />
                    <b-icon-caret-down-fill
                        @click="changeDirection(data.field, '', 'servicesTable')"
                        v-if="data.field.direction == 'desc'"
                    />
                    <b-icon-caret-down
                        @click="changeDirection(data.field, 'desc', 'servicesTable')"
                        v-if="(data.field.direction == '' || data.field.direction == 'asc')"
                    />
                    </span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Обновляем информацию по услугам...</strong>
                </div>
              </template>
              <template v-slot:cell(statcard_id)="data">
                <span class="text-nowrap" title="Добавить в отобранные" v-if="data.value"><b-icon-bookmark-plus/>&nbsp;{{
                    data.value
                  }}</span>
              </template>
              <template v-slot:empty>
                <div class="text-center text-muted">
                  Нет услуг
                </div>
              </template>
            </b-table>
            <b-pagination
                :per-page="perPage"
                :total-rows="SERVICES.total"
                align="center"
                aria-controls="servicesTable"
                v-model="currentPageServices">
            </b-pagination>
          </b-card-text>
        </b-tab>
        <b-tab lazy title="Приемы">
          <b-table
              :busy=isBusyStatcards
              :current-page="currentPageStatcards"
              :fields="fieldsStatcards"
              :items="itemsProviderStatcards"
              :per-page="perPage"
              hover
              id="statcardsTable"
              primary-key="statcard_id"
              rel="statcardsTable"
              show-empty
          >
            <template v-slot:head()="data">
                  <span class="text-nowrap">
                  {{ data.label }}
                    <b-icon-caret-up-fill
                        @click="changeDirection(data.field, '', 'statcardsTable')"
                        v-if="data.field.direction == 'asc'"
                    />
                    <b-icon-caret-up
                        @click="changeDirection(data.field, 'asc', 'statcardsTable')"
                        v-if="(data.field.direction == '' || data.field.direction == 'desc')"
                    />
                    <b-icon-caret-down-fill
                        @click="changeDirection(data.field, '', 'statcardsTable')"
                        v-if="data.field.direction == 'desc'"
                    />
                    <b-icon-caret-down
                        @click="changeDirection(data.field, 'desc', 'statcardsTable')"
                        v-if="(data.field.direction == '' || data.field.direction == 'asc')"
                    />
                    </span>
            </template>
            <template v-slot:empty>
              <div class="text-center text-muted">
                Нет приемов
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Обновляем информацию по приемам...</strong>
              </div>
            </template>

            <template v-slot:cell(service_list)="data">
              <b-table
                  :fields="fieldsServiceList"
                  :items=data.value
                  @row-clicked="(item) => servicesListRowClicked(item)"
                  borderless
                  fixed="fixed"
                  head-variant="none"
                  primary-key="service_id"
                  small
                  table-variant="light"
              >
                <template v-slot:cell(service_name)="data">
                  <span title="Добавить в отобранные" v-if="data.value"><b-icon-bookmark-plus/>&nbsp;{{
                      data.value
                    }}</span>
                </template>
              </b-table>
            </template>
            <template v-slot:cell(protocol_list)="data">
              <b-table
                  :fields="fieldsProtocolList"
                  :items=data.value
                  @row-clicked="(item) => protocolsListRowClicked(item)"
                  borderless
                  fixed="fixed"
                  head-variant="none"
                  primary-key="protocol_id"
                  small
                  table-variant="light"
              >
                <template v-slot:cell(protocol_name)="data">
                  <span title="Добавить в отобранные" v-if="data.value"><b-icon-bookmark-plus/>&nbsp;{{
                      data.value
                    }}</span>
                </template>
                <template v-slot:cell(protocol_id)="data">
                  <File :protocol_id="data.value" prefix="statcard"></File>
                </template>
              </b-table>
            </template>

          </b-table>
          <b-pagination
              :per-page="perPage"
              :total-rows="STATCARDS.total"
              align="center"
              aria-controls="statcardsTable"
              v-model="currentPageStatcards">
          </b-pagination>
        </b-tab>
        <b-tab lazy title="Протоколы">
          <b-table
              :busy=isBusyProtocols
              :current-page="currentPageProtocols"
              :fields="fieldsProtocols"
              :items="itemsProviderProtocols"
              :per-page="perPage"
              @row-clicked="(item) => protocolsRowClicked(item)"
              hover
              id="protocolsTable"
              primary-key="protocol_id"
              rel="protocolsTable"
              show-empty

          >
            <template v-slot:head()="data">
                  <span class="text-nowrap">
                  {{ data.label }}
                    <b-icon-caret-up-fill
                        @click="changeDirection(data.field, '', 'protocolsTable')"
                        v-if="data.field.direction == 'asc'"
                    />
                    <b-icon-caret-up
                        @click="changeDirection(data.field, 'asc', 'protocolsTable')"
                        v-if="(data.field.direction == '' || data.field.direction == 'desc')"
                    />
                    <b-icon-caret-down-fill
                        @click="changeDirection(data.field, '', 'protocolsTable')"
                        v-if="data.field.direction == 'desc'"
                    />
                    <b-icon-caret-down
                        @click="changeDirection(data.field, 'desc', 'protocolsTable')"
                        v-if="(data.field.direction == '' || data.field.direction == 'asc')"
                    />
                    </span>
            </template>
            <template v-slot:empty>
              <div class="text-center text-muted">
                Нет протоколов
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Обновляем информацию по протоколам...</strong>
              </div>
            </template>
            <template v-slot:cell(protocol_id)="data">
              <File :protocol_id="data.value" prefix="protocol"></File>
            </template>
            <template v-slot:cell(statcard_id)="data">
              <span class="text-nowrap" title="Добавить в отобранные" v-if="data.value"><b-icon-bookmark-plus/>&nbsp;{{
                  data.value
                }}</span>
            </template>

          </b-table>
          <b-pagination
              :per-page="perPage"
              :total-rows="PROTOCOLS.total"
              align="center"
              aria-controls="protocolsTable"
              v-model="currentPageProtocols">
          </b-pagination>
        </b-tab>

        <b-tab lazy title="Исследования">
          <b-table
              :busy=isBusyResearches
              :current-page="currentPageResearches"
              :fields="fieldsResearches"
              :items="itemsProviderResearches"
              :per-page="perPage"
              @row-clicked="(item) => researchesRowClicked(item)"
              hover
              id="researchesTable"
              primary-key="research_id"
              rel="researchesTable"
              show-empty

          >
            <template v-slot:head()="data">
                  <span class="text-nowrap">
                  {{ data.label }}
                    <b-icon-caret-up-fill
                        @click="changeDirection(data.field, '', 'researchesTable')"
                        v-if="data.field.direction == 'asc'"
                    />
                    <b-icon-caret-up
                        @click="changeDirection(data.field, 'asc', 'researchesTable')"
                        v-if="(data.field.direction == '' || data.field.direction == 'desc')"
                    />
                    <b-icon-caret-down-fill
                        @click="changeDirection(data.field, '', 'researchesTable')"
                        v-if="data.field.direction == 'desc'"
                    />
                    <b-icon-caret-down
                        @click="changeDirection(data.field, 'desc', 'researchesTable')"
                        v-if="(data.field.direction == '' || data.field.direction == 'asc')"
                    />
                    </span>
            </template>
            <template v-slot:empty>
              <div class="text-center text-muted">
                Нет исследований
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Обновляем информацию по исследованиям...</strong>
              </div>
            </template>
            <template v-slot:cell(research_id)="data">
              <Link :research_id="data.value" prefix="research"/>
            </template>
            <template v-slot:cell(statcard_id)="data">
              <span class="text-nowrap" title="Добавить в отобранные" v-if="data.value"><b-icon-bookmark-plus/>&nbsp;{{
                  data.value
                }}</span>
            </template>

          </b-table>
          <b-pagination
              :per-page="perPage"
              :total-rows="RESEARCHES.total"
              align="center"
              aria-controls="researchesTable"
              v-model="currentPageResearches">
          </b-pagination>
        </b-tab>

        <b-tab lazy title="Протоколы стационара">
          <b-table
              :busy=isBusyStprotocols
              :current-page="currentPageStprotocols"
              :fields="fieldsStprotocols"
              :items="itemsProviderStprotocols"
              :per-page="perPage"
              @row-clicked="(item) => stprotocolsRowClicked(item)"
              hover
              id="stprotocolsTable"
              primary-key="stprotocol_id"
              rel="stprotocolsTable"
              show-empty

          >
            <template v-slot:head()="data">
                  <span class="text-nowrap">
                  {{ data.label }}
                    <b-icon-caret-up-fill
                        @click="changeDirection(data.field, '', 'stprotocolsTable')"
                        v-if="data.field.direction == 'asc'"
                    />
                    <b-icon-caret-up
                        @click="changeDirection(data.field, 'asc', 'stprotocolsTable')"
                        v-if="(data.field.direction == '' || data.field.direction == 'desc')"
                    />
                    <b-icon-caret-down-fill
                        @click="changeDirection(data.field, '', 'stprotocolsTable')"
                        v-if="data.field.direction == 'desc'"
                    />
                    <b-icon-caret-down
                        @click="changeDirection(data.field, 'desc', 'stprotocolsTable')"
                        v-if="(data.field.direction == '' || data.field.direction == 'asc')"
                    />
                    </span>
            </template>
            <template v-slot:empty>
              <div class="text-center text-muted">
                Нет протоколов
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Обновляем информацию по протоколам...</strong>
              </div>
            </template>
            <template v-slot:cell(stprotocol_id)="data">
              <Stfile :stprotocol_id="data.value" prefix="stprotocol"></Stfile>
            </template>
            <template v-slot:cell(statcard_id)="data">
              <span class="text-nowrap" title="Добавить в отобранные" v-if="data.value"><b-icon-bookmark-plus/>&nbsp;{{
                  data.value
                }}</span>
            </template>

          </b-table>
          <b-pagination
              :per-page="perPage"
              :total-rows="STPROTOCOLS.total"
              align="center"
              aria-controls="stprotocolsTable"
              v-model="currentPageStprotocols">
          </b-pagination>
        </b-tab>

        <b-tab lazy title="Отобранные">
          <template v-slot:title>
            <b-icon-bookmark v-if="filterCount==0"/>
            <b-icon-bookmarks-fill
                v-if="filterCount > 0"/>
            Отобранные: {{ filterCount }}
          </template>
          <b-card no-body>
            <b-tabs card v-model="tabFilterIndex">
              <!-- Render Tabs, supply a unique `key` to each tab -->
              <b-tab :key="'dyn-tab-' + item.id +'-'+ item.type" :title="'Tab ' + item.id"
                     v-for="item in SELECTEDBYEXPERT">
                <Statcard :statcard_id="item.id" v-if="item.type=='statcard'"/>
                <Protocol :protocol_id="item.id" v-if="item.type=='protocol'"/>
                <Research :research_id="item.id" v-if="item.type=='research'"/>
                <Stprotocol :stprotocol_id="item.id" v-if="item.type=='stprotocol'"/>
                <Service :service_id="item.id" v-if="item.type=='service'"/>
                <template v-slot:title>
                  <template v-if="item.type=='statcard'">
                    Прием
                  </template>
                  <template v-if="item.type=='service'">
                    Услуга
                  </template>
                  <template v-if="item.type=='protocol'">
                    Протокол
                  </template>
                  <template v-if="item.type=='research'">
                    Исследование
                  </template>
                  <template v-if="item.type=='stprotocol'">
                    Протокол стационара
                  </template>
                  № {{ item.id }}
                  <b-link @click="closeTab(item.id, item.type)" class="float-right"
                          href="#" style="padding-left: 5px; padding-top: 1px">
                    <b-icon icon="x-square"></b-icon>
                  </b-link>
                </template>
              </b-tab>
              <!-- Render this if no tabs -->
              <template v-slot:empty>
                <div class="text-center text-muted">
                  Здесь будут показаны отобранные Вами услуги, приемы и протоколы
                </div>
              </template>
            </b-tabs>
          </b-card>
        </b-tab>
      </b-tabs>

    </b-card>

  </div>
</template>

<script>

import {mapGetters} from "vuex";
import File from '@/components/File.vue'
import Stfile from '@/components/Stfile.vue'
import Statcard from '@/components/Statcard.vue'
import Protocol from '@/components/Protocol.vue'
import Link from '@/components/Link.vue'
import Stprotocol from '@/components/Stprotocol.vue'
import Service from '@/components/Service.vue'

export default {
  name: "PatientCard",
  components: {
    File,
    Stfile,
    Statcard,
    Protocol,
    Link,
    Stprotocol,
    Service
  },
  props: {
    "mppId": {
      type: Number,
      required: true,
      default: 0
    },
  },
  data() {
    return {
      selectedStatcardItems: [],

      tabIndex: 1,
      // Вкладка с фильтрами
      tabFilterIndex: 1,
      // Пагинация
      currentPageServices: 1,
      currentPageStatcards: 1,
      currentPageProtocols: 1,
      currentPageResearches: 1,
      currentPageStprotocols: 1,
      perPage: 20,

      isBusyServices: false,
      isBusyStatcards: false,
      isBusyProtocols: false,
      isBusyResearches: false,
      isBusyStprotocols: false,
      fieldsServices: [
        // { key: "selected" },
        {key: 'service_name', label: 'Код и название услуги', class: 'text-left', direction: ''},
        {key: 'service_count', label: 'Кол-во услуг', class: 'text-center', direction: ''},
        {key: 'service_date', label: 'Дата оказания', class: 'text-center', direction: 'asc'},
        {key: 'statcard_id', label: 'Номер приёма', class: 'text-center', direction: ''},
        {key: 'mkb_code_name', label: 'Основной диагноз приёма', class: 'text-left', direction: ''},
        {key: 'spec_name', label: 'Специальность', class: 'text-left', direction: ''},
        {key: 'user_name', label: 'ФИО врача', class: 'text-left', direction: ''},
      ],
      fieldsStatcards: [

        {key: 'statcard_date', label: 'Дата приёма', class: 'text-center', direction: 'asc'},
        {key: 'spec_name', label: 'Специальность', class: 'text-left', direction: ''},
        {key: 'user_name', label: 'ФИО врача', class: 'text-left', direction: ''},
        {key: 'service_list', label: 'Список оказанных услуг', class: 'text-center'},
        {key: 'protocol_list', label: 'Список оформленных протоколов', class: 'text-center'},

      ],
      fieldsServiceList: [
        {key: 'service_name', label: 'Код и название услуги', class: 'text-left', direction: ''},
        {key: 'service_count', label: 'Кол-во услуг', class: 'text-center', direction: ''},
      ],
      fieldsProtocolList: [
        {key: 'protocol_name', label: 'Название протокола', class: 'text-left', direction: ''},
        {key: 'protocol_id', label: 'Протокол', class: 'text-center', direction: ''},
      ],
      //Protocol Id	Protocol Name	Protocol Url
      fieldsProtocols: [
        {key: 'protocol_date', label: 'Дата протокола', class: 'text-center', direction: 'asc'},
        {key: 'protocol_name', label: 'Название протокола', class: 'text-left', direction: ''},
        {key: 'statcard_id', label: 'Номер приёма', class: 'text-center', direction: ''},
        {key: 'spec_name', label: 'Специальность', class: 'text-left', direction: ''},
        {key: 'user_name', label: 'ФИО врача', class: 'text-left', direction: ''},
        {key: 'spec_name', label: 'Специальность', class: 'text-left', direction: ''},
        {key: 'protocol_id', label: 'Протокол', class: 'text-center'},
      ],
      fieldsResearches: [
        {key: 'research_date', label: 'Дата проведения', class: 'text-center', direction: 'asc'},
        {key: 'research_name', label: 'Тип оборудования', class: 'text-left', direction: ''},
        {key: 'research_id', label: 'Снимок', class: 'text-center'},
      ],
      fieldsStprotocolList: [
        {key: 'stprotocol_name', label: 'Название протокола', class: 'text-left', direction: ''},
        {key: 'stprotocol_id', label: 'Протокол', class: 'text-center'},
      ],
      fieldsStprotocols: [
        {key: 'stprotocol_date', label: 'Дата протокола', class: 'text-center', direction: 'asc'},
        {key: 'stprotocol_name', label: 'Название протокола', class: 'text-left', direction: ''},
        {key: 'spec_name', label: 'Специальность', class: 'text-left', direction: ''},
        {key: 'user_name', label: 'ФИО врача', class: 'text-left', direction: ''},
        {key: 'spec_name', label: 'Специальность', class: 'text-left', direction: ''},
        {key: 'stprotocol_id', label: 'Протокол', class: 'text-center'},
      ]
    }
  },
  computed: {
    ...mapGetters(
        ['isLogged', 'PATIENT', 'SERVICES', 'STATCARDS', 'PROTOCOLS', 'RESEARCHES', 'STPROTOCOLS', 'SELECTEDBYEXPERT']),

    filterCount() {
      return this.SELECTEDBYEXPERT.length
    },
    filterItems0() {
      return this.SELECTEDBYEXPERT[0].list
    },
    title() {
      if (this.PATIENT) {
        return this.PATIENT.person_fullname + ' ' + this.misDateFormat(this.PATIENT.person_birthday);
      } else {
        return ''
      }
    },
    researchLink() {
      return location.protocol + '//' + location.hostname + `/api/research/`;
    },
    subTitle() {
      if (this.PATIENT) {
        return 'Номер карты: ' + this.PATIENT.person_card +
            ' Номер страхового полиса: ' + this.PATIENT.dms_polis_num +
            ' Прикрепление: c ' +
            this.misDateFormat(this.PATIENT.attachment_date_begin) +
            ' по ' +
            this.misDateFormat(this.PATIENT.attachment_date_end) +
            ' Договор(гарантийное письмо): ' + this.PATIENT.contract_garant_letter_name
      } else {
        return ''
      }
    }
  },
  created() {
  },
  mounted() {

  },
  methods: {
    changeDirection(field, state, table) {
      if (table == 'servicesTable') {
        for (let i = 0; i < this.fieldsServices.length; i++) {
          if (this.fieldsServices[i].key == field.key) {
            this.fieldsServices[i].direction = state
          }
        }
        this.$root.$emit('bv::refresh::table', 'servicesTable')
      }
      if (table == 'statcardsTable') {
        for (let i = 0; i < this.fieldsStatcards.length; i++) {
          if (this.fieldsStatcards[i].key == field.key) {
            this.fieldsStatcards[i].direction = state
          }
        }
        this.$root.$emit('bv::refresh::table', 'statcardsTable')
      }
      if (table == 'protocolsTable') {
        for (let i = 0; i < this.fieldsProtocols.length; i++) {
          if (this.fieldsProtocols[i].key == field.key) {
            this.fieldsProtocols[i].direction = state
          }
        }
        this.$root.$emit('bv::refresh::table', 'protocolsTable')
      }
      if (table == 'researchesTable') {
        for (let i = 0; i < this.fieldsResearches.length; i++) {
          if (this.fieldsResearches[i].key == field.key) {
            this.fieldsResearches[i].direction = state
          }
        }
        this.$root.$emit('bv::refresh::table', 'researchesTable')
      }
      if (table == 'stprotocolsTable') {
        for (let i = 0; i < this.fieldsStprotocols.length; i++) {
          if (this.fieldsStprotocols[i].key == field.key) {
            this.fieldsStprotocols[i].direction = state
          }
        }
        this.$root.$emit('bv::refresh::table', 'stprotocolsTable')
      }

    },
    itemsProviderServices(ctx, callback) {
      this.isBusyServices = true
      let multuSort = []
      for (let i = 0; i < this.fieldsServices.length; i++) {
        if (this.fieldsServices[i].direction != undefined) {
          if (this.fieldsServices[i].direction != '') {
            // Положим поля сортировки в массив
            multuSort.push({field: this.fieldsServices[i].key, order: this.fieldsServices[i].direction})
          }
        }
      }
      this.$store
          .dispatch('services', {
            mppId: this.mppId,
            offset: (ctx.currentPage - 1),
            limit: (ctx.perPage),
            multiSort: multuSort
          })
          .then(() => {
            this.isBusyServices = false
            callback(this.SERVICES.list)
            if (this.$route.path !== '/info') {
              this.$router.push({name: 'Info'})
            }
          })
          .catch(err => {
            console.log(err)
            this.isBusyServices = false
            callback([])
          })
    },
    itemsProviderStatcards(ctx, callback) {
      this.isBusyStatcards = true
      let multuSort = []
      for (let i = 0; i < this.fieldsStatcards.length; i++) {
        if (this.fieldsStatcards[i].direction != undefined) {
          if (this.fieldsStatcards[i].direction != '') {
            // Положим поля сортировки в массив
            multuSort.push({field: this.fieldsStatcards[i].key, order: this.fieldsStatcards[i].direction})
          }
        }
      }
      this.$store
          .dispatch('statcards', {
            mppId: this.mppId,
            offset: (ctx.currentPage - 1),
            limit: (ctx.perPage),
            multiSort: multuSort
          })
          .then(() => {
            this.isBusyStatcards = false
            callback(this.STATCARDS.list)
            if (this.$route.path !== '/info') {
              this.$router.push({name: 'Info'})
            }
          })
          .catch(err => {
            console.log(err)
            this.isBusyStatcards = false
            callback([])
          })
    },

    itemsProviderResearches(ctx, callback) {
      this.isBusyResearches = true
      let multuSort = []
      for (let i = 0; i < this.fieldsResearches.length; i++) {
        if (this.fieldsResearches[i].direction != undefined) {
          if (this.fieldsResearches[i].direction != '') {
            // Положим поля сортировки в массив
            multuSort.push({field: this.fieldsResearches[i].key, order: this.fieldsResearches[i].direction})
          }
        }
      }
      this.$store
          .dispatch('researches', {
            mppId: this.mppId,
            offset: (ctx.currentPage - 1),
            limit: (ctx.perPage),
            multiSort: multuSort
          })
          .then(() => {
            this.isBusyResearches = false
            callback(this.RESEARCHES.list)
            if (this.$route.path !== '/info') {
              this.$router.push({name: 'Info'})
            }
          })
          .catch(err => {
            console.log(err)
            this.isBusyResearches = false
            callback([])
          })
    },

    itemsProviderProtocols(ctx, callback) {
      this.isBusyProtocols = true
      let multuSort = []
      for (let i = 0; i < this.fieldsProtocols.length; i++) {
        if (this.fieldsProtocols[i].direction != undefined) {
          if (this.fieldsProtocols[i].direction != '') {
            // Положим поля сортировки в массив
            multuSort.push({field: this.fieldsProtocols[i].key, order: this.fieldsProtocols[i].direction})
          }
        }
      }
      this.$store
          .dispatch('protocols', {
            mppId: this.mppId,
            offset: (ctx.currentPage - 1),
            limit: (ctx.perPage),
            multiSort: multuSort
          })
          .then(() => {
            this.isBusyProtocols = false
            callback(this.PROTOCOLS.list)
            if (this.$route.path !== '/info') {
              this.$router.push({name: 'Info'})
            }
          })
          .catch(err => {
            console.log(err)
            this.isBusyProtocols = false
            callback([])
          })
    },
    itemsProviderStprotocols(ctx, callback) {
      this.isBusyStprotocols = true
      let multuSort = []
      for (let i = 0; i < this.fieldsStprotocols.length; i++) {
        if (this.fieldsStprotocols[i].direction != undefined) {
          if (this.fieldsStprotocols[i].direction != '') {
            // Положим поля сортировки в массив
            multuSort.push({field: this.fieldsStprotocols[i].key, order: this.fieldsStprotocols[i].direction})
          }
        }
      }
      this.$store
          .dispatch('stprotocols', {
            mppId: this.mppId,
            offset: (ctx.currentPage - 1),
            limit: (ctx.perPage),
            multiSort: multuSort
          })
          .then(() => {
            this.isBusyStprotocols = false
            callback(this.STPROTOCOLS.list)
            if (this.$route.path !== '/info') {
              this.$router.push({name: 'Info'})
            }
          })
          .catch(err => {
            console.log(err)
            this.isBusyStprotocols = false
            callback([])
          })
    },
    servicesRowClicked(item) {
      if (this.mppId > 0) {
        if (item.statcard_id > 0) {
          //Клик на строке в услугах где есть номер приема
          // Проверим что эта вкладка еще не открыта
          var bAlreadySelected = false
          for (let i = 0; i < this.SELECTEDBYEXPERT.length; i++) {
            if (this.SELECTEDBYEXPERT[i].id == item.statcard_id) {
              if (this.SELECTEDBYEXPERT[i].type == 'statcard') {
                this.tabFilterIndex = i
                bAlreadySelected = true
              }
            }
          }
          if (bAlreadySelected) {
            // Перейти на вкладку - нужно знать ид вкладки
            this.tabIndex = 5
            return
          }

          this.$store
              .dispatch('statcard', {
                mppId: this.mppId,
                statcardId: item.statcard_id,
                type: 'statcard'
              })
              .then(() => {
                if (this.$route.path !== '/info') {
                  this.$router.push({name: 'Info'})
                }
                this.tabIndex = 5
                this.tabFilterIndex++

              })
              .catch(err => {
                this.error = 'Ошибка при получении приема'
                console.log(err)
              })
        }
      }
    },
    servicesListRowClicked(item) {
      if (this.mppId > 0) {
        if (item.service_id > 0) {
          //Клик на строке в услугах где есть номер приема
          // Проверим что эта вкладка еще не открыта
          var bAlreadySelected = false
          for (let i = 0; i < this.SELECTEDBYEXPERT.length; i++) {
            if (this.SELECTEDBYEXPERT[i].id == item.service_id) {
              if (this.SELECTEDBYEXPERT[i].type == 'service') {
                this.tabFilterIndex = i
                bAlreadySelected = true
              }
            }
          }
          if (bAlreadySelected) {
            // Перейти на вкладку - нужно знать ид вкладки
            this.tabIndex = 5
            return
          }

          this.$store
              .dispatch('service', {
                mppId: this.mppId,
                serviceId: item.service_id,
                type: 'service'
              })
              .then(() => {
                if (this.$route.path !== '/info') {
                  this.$router.push({name: 'Info'})
                }
                this.tabIndex = 5
                this.tabFilterIndex++

              })
              .catch(err => {
                this.error = 'Ошибка при получении услуги'
                console.log(err)
              })
        }
      }
    },
    protocolsRowClicked(item) {
      if (this.mppId > 0) {
        if (item.statcard_id > 0) {
          //Клик на строке в услугах где есть номер приема
          // Проверим что эта вкладка еще не открыта
          var bAlreadySelected = false
          for (let i = 0; i < this.SELECTEDBYEXPERT.length; i++) {
            if (this.SELECTEDBYEXPERT[i].id == item.statcard_id) {
              if (this.SELECTEDBYEXPERT[i].type == 'statcard') {
                this.tabFilterIndex = i
                bAlreadySelected = true
              }
            }
          }
          if (bAlreadySelected) {
            // Перейти на вкладку - нужно знать ид вкладки
            this.tabIndex = 5
            return
          }

          this.$store
              .dispatch('statcard', {
                mppId: this.mppId,
                statcardId: item.statcard_id,
                type: 'statcard'
              })
              .then(() => {
                if (this.$route.path !== '/info') {
                  this.$router.push({name: 'Info'})
                }
                this.tabIndex = 5
                this.tabFilterIndex++

              })
              .catch(err => {
                this.error = 'Ошибка при получении приема'
                console.log(err)
              })
        }
      }
    },
    protocolsListRowClicked(item) {
      if (this.mppId > 0) {
        if (item.protocol_id > 0) {
          //Клик на строке в услугах где есть номер приема
          // Проверим что эта вкладка еще не открыта
          var bAlreadySelected = false
          for (let i = 0; i < this.SELECTEDBYEXPERT.length; i++) {
            if (this.SELECTEDBYEXPERT[i].id == item.protocol_id) {
              if (this.SELECTEDBYEXPERT[i].type == 'protocol') {
                this.tabFilterIndex = i
                bAlreadySelected = true
              }
            }
          }
          if (bAlreadySelected) {
            // Перейти на вкладку - нужно знать ид вкладки
            this.tabIndex = 5
            return
          }

          this.$store
              .dispatch('protocol', {
                mppId: this.mppId,
                protocolId: item.protocol_id,
                type: 'protocol'
              })
              .then(() => {
                if (this.$route.path !== '/info') {
                  this.$router.push({name: 'Info'})
                }
                this.tabIndex = 5
                this.tabFilterIndex++

              })
              .catch(err => {
                this.error = 'Ошибка при получении протокола'
                console.log(err)
              })
        }
      }
    },
    stprotocolsRowClicked(item) {
      if (this.mppId > 0) {
        if (item.statcard_id > 0) {
          //Клик на строке в услугах где есть номер приема
          // Проверим что эта вкладка еще не открыта
          var bAlreadySelected = false
          for (let i = 0; i < this.SELECTEDBYEXPERT.length; i++) {
            if (this.SELECTEDBYEXPERT[i].id == item.statcard_id) {
              if (this.SELECTEDBYEXPERT[i].type == 'statcard') {
                this.tabFilterIndex = i
                bAlreadySelected = true
              }
            }
          }
          if (bAlreadySelected) {
            // Перейти на вкладку - нужно знать ид вкладки
            this.tabIndex = 5
            return
          }

          this.$store
              .dispatch('statcard', {
                mppId: this.mppId,
                statcardId: item.statcard_id,
                type: 'statcard'
              })
              .then(() => {
                if (this.$route.path !== '/info') {
                  this.$router.push({name: 'Info'})
                }
                this.tabIndex = 5
                this.tabFilterIndex++

              })
              .catch(err => {
                this.error = 'Ошибка при получении приема'
                console.log(err)
              })
        }
      }
    },
    stprotocolsListRowClicked(item) {
      if (this.mppId > 0) {
        if (item.stprotocol_id > 0) {
          //Клик на строке в услугах где есть номер приема
          // Проверим что эта вкладка еще не открыта
          var bAlreadySelected = false
          for (let i = 0; i < this.SELECTEDBYEXPERT.length; i++) {
            if (this.SELECTEDBYEXPERT[i].id == item.stprotocol_id) {
              if (this.SELECTEDBYEXPERT[i].type == 'stprotocol') {
                this.tabFilterIndex = i
                bAlreadySelected = true
              }
            }
          }
          if (bAlreadySelected) {
            // Перейти на вкладку - нужно знать ид вкладки
            this.tabIndex = 5
            return
          }

          this.$store
              .dispatch('stprotocol', {
                mppId: this.mppId,
                stprotocolId: item.stprotocol_id,
                type: 'stprotocol'
              })
              .then(() => {
                if (this.$route.path !== '/info') {
                  this.$router.push({name: 'Info'})
                }
                this.tabIndex = 5
                this.tabFilterIndex++

              })
              .catch(err => {
                this.error = 'Ошибка при получении протокола'
                console.log(err)
              })
        }
      }
    },
    closeTab(x, type) {
      this.$store
          .dispatch('removeSelectedByExpert', {
            id: x,
            type: type
          })
          .then(() => {
            if (this.$route.path !== '/info') {
              this.$router.push({name: 'Info'})
            }
          })
          .catch(err => {
            this.error = 'Ошибка при удалении'
            console.log(err)
          })
    },
    misDateFormat(sDate) {
      return new Date(sDate).toLocaleDateString('ru-RU', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }).split(' ').join('.');
    }
  }
}
</script>

<style scoped>

</style>
