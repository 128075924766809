<template>
  <div class="cabinet">
    <TopMenu msg="Поиск прикреплений пациентов организации: " />
    <div>
      <b-table
        id="patients"
        striped
        hover
        show-empty
        :items="SEARCH.list"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        sort-icon-left
      >
        <template v-slot:cell(index)="data">
          {{ data.value + data.index + 1 }}
        </template>
        <!-- v-html directive can lead to XSS attack (vue/no-v-html)
        <template v-slot:cell(html)="data">
          <span v-html="data.value" />
        </template>
        -->
        <!-- Optional default data cell scoped slot -->
        <template v-slot:cell()="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(action)="data">
          <b-button
            v-if="data.value==0"
            disabled
          >
            Информация недоступна, обратитесь к администратору
          </b-button>
          <b-button
            v-if="data.value!=0"
            variant="outline-primary"
            v-on:click=info(data.value)
          >
            Подробнее
          </b-button>
        </template>
        <template v-slot:empty>
          <div class="text-center text-muted">
            Нет прикреплений пациентов
          </div>
        </template>
      </b-table>
      <b-pagination
              v-model="currentPage"
              align="center"
              :per-page="perPage"
              aria-controls="patients"
              :total-rows="SEARCH.total">
      </b-pagination>
    </div>
  </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import TopMenu from '@/components/TopMenu.vue'
    export default {
        name: 'Cabinet',
        components: {
            TopMenu
        },
        data () {
            return {
              currentPage: 1,
              perPage: 20,
              sortBy: 'person_fullname',
              sortDesc: false,
              fields: [
                { key: 'index', label: '№ п/п',
                  formatter: () => {
                    return ((this.currentPage -1) * this.perPage);
                  }
                  },
                { key: 'person_fullname', label: 'Пациент',  class: 'text-left', sortable: true },
                { key: 'person_birthday', label: 'Дата рождения',  sortable: true ,
                  formatter: (value, key, item) => {
                    return this.misDateFormat(item.person_birthday);
                  }
                },
                { key: 'contract_garant_letter_name', label: 'Договор', class: 'text-left', sortable: true },
                { key: 'attachment_date_begin', label: 'Дата начала',  sortable: true,
                  formatter: (value, key, item) => {
                    return this.misDateFormat(item.attachment_date_begin);
                  }
                },
                { key: 'attachment_date_end', label: 'Дата окончания',  sortable: true,
                  formatter: (value, key, item) => {
                    return this.misDateFormat(item.attachment_date_end);
                  }
                },
                { key: 'dms_polis_num', label: 'Номер страхового полиса', class: 'text-left', sortable: true },
                { key: 'action', label: 'Действия',
                  formatter: (value, key, item) => {
                    if (item.hide_from_sk_experts === 1) {
                     // return 'Пациент не разрешил доступ'
                      return 0
                    } else {
                      return item.mpp_id
                    }
                  }},
              ],
            }
        },
        computed: {
          ...mapGetters(
                  [
                    'isLogged', 'USER', 'SEARCH'
                  ]),
        },
        methods: {
            getSelectedPatient(mppId) {
                for (let item in this.SEARCH.list) {
                    if (mppId == this.SEARCH.list[item].mpp_id) {
                        return this.SEARCH.list[item]
                    }
                }
                return false
            },
            info(mppId) {
                //TODO нужно сохранить выбранного пациента для карточки
                if (mppId != undefined) {
                this.$store
                    .dispatch('setPatient', {
                        selectedPatient: this.getSelectedPatient(mppId),
                    })
                    .then(() => {
                        //
                        //https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
                        //this.$router.push({name: 'Cabinet'},() => {})
                        if (this.$route.path != '/info') {
                            this.$router.push({name: 'Info'})
                        }
                    })
                    .catch(err => {
                        this.error = 'Укажите корректный прикрепления'
                        console.log(err)
                    })
                }
            },
            misDateFormat(sDate) {
              return new Date(sDate).toLocaleDateString('ru-RU', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric'
              }).split(' ').join('.');
            }
        }
    }
</script>

  <style scoped>

  </style>
