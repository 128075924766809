<template>
  <b-table
    :busy=isBusyStatcards
    :fields="fieldsStatcards"
    :id=table_id
    :items=selectedStatcardItems
    hover
    primary-key="statcard_id"
  >
    <template v-slot:table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong> Обновляем информацию по приему...</strong>
      </div>
    </template>

    <template v-slot:cell(service_list)="data">
      <b-table :fields="fieldsServiceList" :items=data.value borderless fixed="fixed" head-variant="none" small
               table-variant="light"></b-table>
    </template>
    <template v-slot:cell(protocol_list)="data">
      <b-table :fields="fieldsProtocolList" :items=data.value borderless fixed="fixed" head-variant="none" small
               table-variant="light">

        <template v-slot:cell(protocol_id)="data">
          <File :protocol_id="data.value" prefix="statcard"></File>
        </template>
      </b-table>
    </template>

  </b-table>

</template>

<script>
    import {mapGetters} from "vuex";
    import File from '@/components/File.vue'

    export default {
        name: "Statcard",
        components: {
            File,
        },
        props: {
            "statcard_id": {
                type: Number,
                required: true,
                default: 0
            },
        },
        data() {
            return {
                isBusyStatcards: false,
                selectedStatcardItems: [],
                fieldsStatcards: [

                    {key: 'statcard_date', label: 'Дата приёма', class: 'text-center' },
                    {key: 'spec_name', label: 'Специальность', class: 'text-left' },
                    {key: 'user_name', label: 'ФИО врача', class: 'text-left' },
                    {key: 'service_list', label: 'Список оказанных услуг', class: 'text-center' },
                    {
                        key: 'protocol_list',
                        label: 'Список оформленных протоколов',
                        class: 'text-center',
                        sortable: false
                    },

                ],
                fieldsServiceList: [
                    {key: 'service_name', label: 'Код и название услуги', class: 'text-left' },
                    {key: 'service_count', label: 'Кол-во услуг', class: 'text-center' },
                ],
                fieldsProtocolList: [
                    {key: 'protocol_name', label: 'Название протокола', class: 'text-left' },
                    {key: 'protocol_id', label: 'Протокол', class: 'text-center' },
                ],
            }
        },

        computed: {
            ...mapGetters(
                ['isLogged', 'PATIENT', 'SELECTEDBYEXPERT']),
            table_id() {
                return 'selectedStatcard-'
            },
        },
        mounted() {

            this.getFilterItems()
        },
        methods: {
            getFilterItems() {
                const id = this.statcard_id;
                if (id > 0) {
                    for (let i = 0; i < this.SELECTEDBYEXPERT.length; i++) {
                        if (this.SELECTEDBYEXPERT[i].id == id) {
                            if (this.SELECTEDBYEXPERT[i].type == 'statcard') {
                                this.selectedStatcardItems = this.SELECTEDBYEXPERT[i].list
                            }
                        }
                    }
                }
                return []
            },
        }
    }
</script>

<style scoped>

</style>
