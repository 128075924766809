<template>
  <b-table
    :busy=isBusyServices
    :fields="fieldsServices"
    :id=table_id
    :items="selectedServiceItems"
    hover
    primary-key="service_id"
  >
    <template v-slot:table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong> Обновляем информацию по услуге...</strong>
      </div>
    </template>
  </b-table>

</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: "Service",
        components: {},
        props: {
            "service_id": {
                type: Number,
                required: true,
                default: 0
            },
        },
        data() {
            return {
                isBusyServices: false,
                selectedServiceItems: [],
                fieldsServices: [
                    {key: 'service_name', label: 'Код и название услуги', class: 'text-left' },
                    {key: 'service_count', label: 'Кол-во услуг', class: 'text-center' },
                    {key: 'service_date', label: 'Дата оказания', class: 'text-center' },
                    {key: 'statcard_id', label: 'Номер приёма', class: 'text-center' },
                    {key: 'mkb_code_name', label: 'Основной диагноз приёма', class: 'text-left' },
                    {key: 'spec_name', label: 'Специальность', class: 'text-left' },
                    {key: 'user_name', label: 'ФИО врача', class: 'text-left' },
                ],
            }
        },

        computed: {
            ...mapGetters(
                ['isLogged', 'PATIENT', 'SELECTEDBYEXPERT']),
            table_id() {
                return 'selectedService-'
            },
        },
        mounted() {
            this.getFilterItems()
        },
        methods: {
            getFilterItems() {
                const id = this.service_id;
                if (id > 0) {
                    for (let i = 0; i < this.SELECTEDBYEXPERT.length; i++) {
                        if (this.SELECTEDBYEXPERT[i].id == id) {
                            if (this.SELECTEDBYEXPERT[i].type == 'service') {
                                this.selectedServiceItems = this.SELECTEDBYEXPERT[i].list
                            }
                        }
                    }
                }
                return []
            },
        }
    }
</script>

<style scoped>

</style>
