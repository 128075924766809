<template>
  <div>
    <b-button
      :id=customButtonId
      @click=getFile(protocol_id)
      variant="outline-primary"
    >
      <b-spinner label="Загружается..." small v-if="isBusyFile"></b-spinner>
      PDF
    </b-button>
    <b-modal
            :id = customId
            scrollable
            centered
            size="xl"
            title="Просмотр протокола"
            button-size="sm"
            ok-only
            okTitle="Закрыть"
            no-stacking
    >
      <pdf
              ref="myPdfComponent"
              v-for="i in numPages"
              :key="i"
              :src="url"
              :page="i"
              style="display: inline-block; width: 100%"
      ></pdf>

    </b-modal>

  </div>
</template>

<script>
    import pdf from 'vue-pdf'
    import {mapGetters} from "vuex"

    export default {
      name: "File",
      components: {
          pdf
      },
      props: {
          "protocol_id": {
              type: Number,
              required: true,
              default: 0
          },
          "prefix": {
              type: String,
              required: true,
              default: ''
        },
      },
      data() {
        return {
          url: '',
          page: 1,
          numPages: 0,
            isBusyFile: false
       }
      },
      computed: {
          ...mapGetters(
              ['isLogged', 'PATIENT', 'FILE']),
        customId() {
            return this.prefix+'_modal_'+this.protocol_id
        },
        customButtonId() {
          return 'protocol_button_'+this.protocol_id
        }
      },
      mounted() {
      },
      methods: {
        getFile(protocol_id) {
            this.isBusyFile = true
            this.$store
                .dispatch('file', {
                    protocol_id: protocol_id,
                })
                .then(() => {
                    var loadingTask = pdf.createLoadingTask(this.FILE);
                    this.url = loadingTask
                    loadingTask.promise.then(pdf => {
                        this.numPages = pdf.numPages;
                    });
                    this.isBusyFile = false
                    this.$nextTick(() => {
                        if (this.url !== '') {
                            this.$bvModal.show(this.customId)
                        }
                    })
                  })
                  .catch(err => {
                    this.error = 'Укажите URL'
                    console.log(err)
                  })
        }

      }
  }
</script>

