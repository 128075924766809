<template>
  <div>
    <b-button
      :id=customButtonId
      @click=getLink(research_id)
      variant="outline-primary"
    >
      <b-spinner label="Загружается..." small v-if="isBusyLink"></b-spinner>
      Исследование
    </b-button>
  </div>
</template>

<script>
    import {mapGetters} from "vuex"

    export default {
      name: "Link",
      components: {
      },
      props: {
          "research_id": {
              type: Number,
              required: true,
              default: 0
          },
          "prefix": {
              type: String,
              required: true,
              default: ''
        },
      },
      data() {
        return {
          url: '',
          page: 1,
          numPages: 0,
            isBusyLink: false
       }
      },
      computed: {
          ...mapGetters(
              ['isLogged', 'PATIENT', 'RESEARCH']),
        customId() {
            return this.prefix+'_modal_'+this.research_id
        },
        customButtonId() {
          return 'research_button_'+this.research_id
        }
      },
      mounted() {
      },
      methods: {
        getLink(research_id) {
            this.isBusyLink = true
            this.$store
                .dispatch('research', {
                  research_id: research_id,
                })
                .then(() => {
                    this.isBusyLink = false
                    this.$nextTick(() => {
                        if (this.RESEARCH !== '') {
                          window.open(this.RESEARCH, '_blank');
                        }
                    })
                  })
                  .catch(err => {
                    this.isBusyLink = false;
                    this.error = 'Укажите URL'
                    console.log(err)
                  })
        }

      }
  }
</script>

